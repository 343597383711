'use client';

import { useSingleChecklistProgress } from '@/hooks/useChapterProgress';
import { Switch } from '@headlessui/react';
import {
	mdiCheckDecagram,
	mdiCheckboxBlankOutline,
	mdiCheckboxMarked,
	mdiMagnify,
	mdiReload,
} from '@mdi/js';
import Icon from '@mdi/react';
import { motion, useReducedMotion } from 'motion/react';
import { usePathname, useRouter } from 'next/navigation';
import IconButton from '../Button/IconButton';
import EmojiText from '../EmojiText/EmojiText';
import InteractiveSection from './InteractiveSection';

interface CheckListStep {
	text: string;
	description?: string;
	searchTerm?: string;
}

const CheckList = ({
	id,
	title,
	steps,
}: {
	id: string;
	title: string;
	steps: string;
}) => {
	const router = useRouter();
	const pathname = usePathname() ?? '';
	const firstPathnameSegment = pathname.split('/')[1];
	const guidelinesLink = `/${firstPathnameSegment}/richtlijnen`;

	const reduceMotion = useReducedMotion();

	const parsedSteps = JSON.parse(steps) as CheckListStep[];

	const { checklistProgress, setChecklistProgress } =
		useSingleChecklistProgress(pathname, id);

	const isChecked = (index: number) =>
		!!checklistProgress?.selected_option_indexes.includes(index);

	// Show reset button if more than 3 items have been checked
	const isResetButtonVisible =
		(checklistProgress?.selected_option_indexes.length ?? 0) > 3;

	const isFinished =
		parsedSteps.length ===
		checklistProgress?.selected_option_indexes.length;

	return (
		<InteractiveSection
			headingLevel="h2"
			title={title}
			subtitle="Eerste stappen"
			emoji={
				<motion.div
					key={checklistProgress?.selected_option_indexes.join('')}
					animate={{
						scale: reduceMotion ? 1 : [1, 1.1, 1],
					}}
				>
					<EmojiText>{isFinished ? '🥳' : '😄'}</EmojiText>
				</motion.div>
			}
			colorVariant="primary"
		>
			<ol
				aria-label={`Eerste stappen - ${title}`}
				className="grid items-stretch gap-1 overflow-hidden rounded-3xl bg-white p-2 text-base text-primary-900 shadow-md sm:text-lg dark:bg-black/30 dark:text-primary-100"
			>
				{parsedSteps.map((step, index) => (
					<li key={index} className="flex items-center gap-2">
						<Switch
							className={`flex w-full gap-3 rounded-2xl bg-gradient-to-r p-4 text-start font-bold transition-all duration-300 active:shadow hover:bg-primary/10 hover:shadow-sm ${
								isChecked(index)
									? 'from-primary/10 to-primary-100/0'
									: 'from-white/0 to-white/0'
							}`}
							checked={isChecked(index)}
							onChange={(isChecked) => {
								const newSelectedOptionIndexes = isChecked
									? [
											...(checklistProgress?.selected_option_indexes ??
												[]),
											index,
										]
									: (checklistProgress?.selected_option_indexes.filter(
											(i) => i !== index,
										) ?? []);
								setChecklistProgress(newSelectedOptionIndexes);
							}}
						>
							<motion.div
								key={`checkbox-${index}-${isChecked(index)}`}
								className="shrink-0"
								animate={{
									scale: reduceMotion
										? 1
										: [1, isChecked(index) ? 1.15 : 0.9, 1],
									transition: {
										duration: 0.3,
									},
								}}
							>
								<Icon
									path={
										isChecked(index)
											? mdiCheckboxMarked
											: mdiCheckboxBlankOutline
									}
									size={1.2}
									className="-translate-y-0.5"
									aria-hidden
								/>
							</motion.div>
							{step.text}
						</Switch>
						{!!step.searchTerm && (
							<IconButton
								colorVariant="primary"
								className="shrink-0"
								onClick={() => {
									router.push(
										step.searchTerm?.startsWith('/')
											? step.searchTerm
											: `/zoeken?q=${step.searchTerm}`,
									);
								}}
								icon={mdiMagnify}
								isLarge
								title={
									step.searchTerm?.startsWith('/')
										? `Zoek naar de pagina "${step.searchTerm}"`
										: `Zoek meer over dit onderwerp: "${step.searchTerm}"`
								}
							/>
						)}
					</li>
				))}
				{isResetButtonVisible && (
					<li className="px-1.5 py-2">
						<IconButton
							icon={mdiReload}
							onClick={() => setChecklistProgress([])}
							colorVariant="primary"
							label="Opnieuw beginnen"
							title="Alle vinkjes uitvinken"
						/>
					</li>
				)}
			</ol>
			<p className="flex gap-2 px-4 pt-5 text-base font-bold text-primary-900 dark:text-white">
				<Icon
					path={mdiCheckDecagram}
					size={0.9}
					className="inline-block shrink-0"
				/>{' '}
				<span>
					Kijk voor de volledige eisen altijd bij{' '}
					<a
						href={guidelinesLink}
						className="whitespace-nowrap underline underline-offset-2"
					>
						jouw richtlijnen
					</a>
					.
				</span>
			</p>
		</InteractiveSection>
	);
};

export default CheckList;
