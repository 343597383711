/* eslint-disable @next/next/no-img-element */
'use client';

import { useChapterProgress } from '@/hooks/useChapterProgress';
import { getNewsItems } from '@/hooks/useNews';
import { mdiCheckDecagram } from '@mdi/js';
import Icon from '@mdi/react';
import { motion } from 'motion/react';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import EmojiText from '../EmojiText/EmojiText';
import { NavigationItem } from '../Navigation/NavigationItem';

const NewsOverview = ({
	fromIndex = 0,
	maxItems = 100,
	headingLevel,
	isDarkMode = false,
	showMoreNewsButton = false,
}: {
	fromIndex?: number;
	maxItems?: number;
	headingLevel?: 2 | 3 | 4 | 5 | 6;
	isDarkMode?: boolean;
	showMoreNewsButton?: boolean;
}) => {
	const [newsItems, setNewsItems] = useState<{
		isLoading: boolean;
		data: {
			items?: NavigationItem[];
		};
	}>({
		isLoading: true,
		data: {},
	});

	useEffect(() => {
		(async () => {
			setNewsItems({
				isLoading: false,
				data: {
					items: await getNewsItems(fromIndex, maxItems),
				},
			});
		})();
	}, [maxItems]);

	if (newsItems.isLoading || !newsItems.data?.items)
		return (
			<p
				className="rounded-lg bg-white p-8 text-center text-lg font-bold dark:bg-black"
				role="status"
			>
				Nieuws ophalen...
			</p>
		);

	return (
		<div className="not-prose">
			<ol className="grid items-stretch justify-center gap-4 md:grid-cols-3">
				{newsItems.data.items.map((newsItem, index) => (
					<NewsCard
						key={`${newsItem.parentPath}${newsItem.name}${index}`}
						newsItem={newsItem}
						headingLevel={headingLevel}
						isDarkMode={
							isDarkMode ||
							(window.matchMedia &&
								window.matchMedia(
									'(prefers-color-scheme: dark)',
								).matches)
						}
					/>
				))}
				{showMoreNewsButton && (
					<MoreNewsCard
						isDarkMode={isDarkMode}
						isWide={newsItems.data.items.length % 3 === 0}
					/>
				)}
			</ol>
		</div>
	);
};

const MoreNewsCard = ({
	isDarkMode,
	isWide,
}: {
	isDarkMode?: boolean;
	isWide?: boolean;
}) => {
	return (
		<motion.li
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
			className={isWide ? 'md:col-span-3' : 'max-w-md md:max-w-sm'}
		>
			<Link
				href="/nieuws"
				className={`group relative flex flex-col items-center justify-center gap-2 p-4 ${
					isWide ? '' : 'md:gap-6 md:p-6'
				} size-full ${
					isDarkMode
						? 'bg-secondary-800 text-white active:bg-secondary-600 hover:bg-secondary-900 focus-visible:border-4 focus-visible:border-white'
						: 'bg-slate-100 text-slate-800 focus-visible:border-4 focus-visible:border-black'
				} rounded-xl text-xl font-bold transition-all hover:shadow-lg md:text-2xl`}
				aria-label="Meer nieuws"
			>
				<div
					className={`flex size-24 items-center justify-center ${
						isWide ? '' : 'md:size-32'
					} emoji-illustration-container pb-0.5 text-4xl ${
						isWide ? '' : 'md:text-6xl'
					} rounded-full bg-secondary-500/50`}
				>
					<span className="transition-all motion-safe:group-hover:-rotate-6 motion-safe:group-hover:scale-110">
						<EmojiText>📰</EmojiText>
					</span>
				</div>
				Meer nieuws
			</Link>
		</motion.li>
	);
};

const NewsCard = ({
	newsItem,
	headingLevel,
	isDarkMode = false,
}: {
	newsItem: NavigationItem;
	headingLevel?: 2 | 3 | 4 | 5 | 6;
	isDarkMode?: boolean;
}) => {
	// Find is article has been marked as completed
	const { isPageCompleted } = useChapterProgress(
		newsItem.parentPath.length > 1
			? newsItem.parentPath.split('/')[0]
			: newsItem.name,
		newsItem,
	);
	const articlePath = [newsItem.parentPath ?? '/', newsItem.name]
		.filter((pathPart) => !!pathPart)
		.join('/');
	const hasMarkedArticleAsCompleted = isPageCompleted(articlePath);

	// Create right tag for header element
	const Header = `h${headingLevel ?? 2}` as keyof JSX.IntrinsicElements;

	// Create aria label
	let ariaLabel = newsItem.metadata?.title;
	if (hasMarkedArticleAsCompleted) {
		ariaLabel += ', gemarkeerd als gelezen';
	}
	if (newsItem.metadata?.date?.created) {
		ariaLabel += `, gepubliceerd op ${new Date(
			newsItem.metadata.date.created,
		).toLocaleDateString('nl-NL', {
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		})}`;
	}

	return (
		<motion.li
			initial={{
				opacity: 0,
			}}
			animate={{
				opacity: 1,
			}}
		>
			<Link
				href={`/${newsItem.parentPath}/${newsItem.name}`}
				className={`shadow-border-bottom-sm group relative block size-full max-w-md md:max-w-sm ${
					isDarkMode
						? 'bg-secondary-900 text-white active:bg-secondary-600 hover:bg-secondary-900'
						: 'bg-white text-slate-800'
				} rounded-xl shadow-md transition-all after:rounded-xl hover:shadow-lg`}
				aria-label={ariaLabel}
			>
				<article className="flex h-full flex-col p-1">
					{hasMarkedArticleAsCompleted && (
						<div
							className="absolute right-1.5 top-1.5 rounded-full bg-white p-1 text-secondary-700"
							title="Gemarkeerd als voltooid"
							aria-label="Gemarkeerd als voltooid"
						>
							<Icon path={mdiCheckDecagram} size={1.25} />
						</div>
					)}
					<img
						src={newsItem.metadata?.thumbnailImage?.url}
						alt={newsItem.metadata?.thumbnailImage?.alt}
						className="rounded-lg object-cover transition-all group-active:opacity-100 group-hover:opacity-80"
						style={{
							aspectRatio: '576 / 384',
						}}
					/>
					<header className="flex grow flex-col justify-between gap-2">
						<Header className="px-2 pt-4 text-xl md:text-base lg:text-xl">
							{newsItem.metadata?.title}
						</Header>
						{newsItem?.metadata?.date?.created && (
							<time
								className={`block px-2 pb-2 text-base ${
									isDarkMode
										? 'text-secondary-100'
										: 'text-secondary-800'
								} text-balance font-semibold`}
								dateTime={newsItem.metadata.date.created}
							>
								{new Date(
									newsItem.metadata.date.created,
								).toLocaleDateString('nl-NL', {
									day: 'numeric',
									month: 'long',
									year: 'numeric',
								})}
							</time>
						)}
					</header>
				</article>
			</Link>
		</motion.li>
	);
};

export default NewsOverview;
